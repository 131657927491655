@import '../_sass/_colors';

#submission-list {
    width: unquote("min(93vw, 600px)");
    margin: auto;
}

#submission-list-header {
    position: relative;
    height: 4rem;
}
#submission-list-label {
    position: absolute;
    left: 0;
    bottom: 0;
}
#weighted-score {
    position: absolute;
    right: 0;
    bottom: 12%;

    cursor: pointer;
}

#submission-list-words {
    display: flex;
    flex-direction: column;
}
