#board-management {
    width: unquote("min(100%, 700px)");
    margin: auto;

    min-height: 7.5rem;
}

.control-button {
    width: unquote("min(30%, 220px)");
    height: 3rem;
    background-color: white;
    
    transition-duration: 0.1s;
    &:hover, &:focus {
        background-color: lightgrey;
    }

    font-size: 2rem;
}

@media (max-width: 600px) {
    .control-button {
        font-size: 1.5rem;
    }
}

@media (max-width: 400px) {
    .control-button {
        font-size: 1.2rem;
    }
}
