@import "colors";

$tile-margin: 5px;
$tile-padding: 0px;
$tile-width: unquote("min(18vw, 115px)");

$tile-border-width: 2px;
$tile-border-color: $blue;
$tile-border-radius: 10px;

$tile-shadow-blur-radius: 20px;
$tile-shadow-spread-radius: 4px;
$tile-shadow-color: rgba(255, 255, 255, 0.3);
