@import '_sass/_colors';
@import '_sass/_tile-variables';


* {
    margin: 0;
    padding: 0;
}

body {
    background-color: $black;

    color: $beige;
    font-family: "Lato", sans-serif;
    font-size: 1.5rem;
    text-align: center;
}
@media (max-width: 600px) {
    body {
        font-size: 1.3rem;
    }
}

h2 {
    margin-left: -15px;
    margin-bottom: 10px;
 
    text-align: left;
    color: $orange;
    font-size: 3rem;
}
@media (max-width: 600px) {
    h2 {
        font-size: 2.5rem;
        margin-left: -8px;
    }
}

.clickable-button {
    margin: $tile-margin;
    position: relative;
 
    border: $tile-border-width solid $tile-border-color;
    border-radius: $tile-border-radius;

    transition-duration: 0.1s;
    &:hover {
        box-shadow: 0 0 $tile-shadow-blur-radius $tile-shadow-spread-radius $tile-shadow-color;
    }  
}
