
#how-to {
    width: unquote("min(93vw, 600px)");
    margin: auto;
}
.how-to-text {
    margin-bottom: 80px;
}


/* Keyboard vs mobile commands */
.mobile-how-to {
    display: none;
}
@media (max-width: 500px) {
    .keyboard-how-to {
        display: none;
    }
    .mobile-how-to {
        display: inline;
    }
}
#keyboard-shortcuts {
    text-align: left;
}
span {
    margin-left: 5px;
    margin-right: 5px;
}
