@import "../_sass/_tile-variables";

.tile {
    width: $tile-width;
    height: $tile-width;

    border-radius: $tile-border-radius;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 4rem;
    font-family: "Lato", sans-serif;

    cursor: pointer;
}
@media (max-width: 600px) {
    .tile {
        font-size: 3rem;
    }
}


.used-tile {
    position: absolute;
    top: 0;
    right: 0;

    background-color: inherit;
    color: $beige;
}

.unused-tile {
    position: absolute;
    top: 0;
    right: 0;

	background-color: white;
	color: $black;

    transition-duration: 0.1s;
    &:hover, &:focus {
        background-color: lightgrey;
    }
}


.tile-num {
    position: absolute;
    top: 3px;
    right: 6px;

    font-size: 1.3rem;
}
@media (max-width: 600px) {
    .tile-num {
        font-size: 1rem;
    }
}
