#LetterGetter {
    margin: auto;
}

#title-container {
    margin-top: 20px;
    margin-bottom: 40px;
}
#board-container {
    margin-bottom: 25px;
}
#submission-container {
    margin-bottom: 25px;
}
#board-management-container {
    margin-bottom: 50px;
}
#submission-list-container {
    margin-bottom: 100px;
}
