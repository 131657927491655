$black: rgb(20, 15, 15);
$grey: rgb(30, 30, 40);

$beige: rgb(255, 220, 150);
$dark-beige: rgb(200, 175, 100);

$xlight-blue: rgb(0, 128, 255);
$light-blue: rgb(50, 150, 255);
$blue: rgb(100, 180, 230);
$dark-blue: rgb(0, 50, 100);

$light-orange: rgb(255, 150, 25);
$orange: rgb(255, 85, 0);
$dark-orange: rgb(210, 55, 10);
$xdark-orange: rgb(100, 70, 0);
